import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

function NotFound() {
    return (
      <>
        <Helmet>
            <html lang="en" />
            <title>404 - 六角旅遊網</title>
            <meta name="description" content="Hexschool - Exploring in Kaohsiung" />
            <meta name="theme-color" content="#E6E6FA" />
        </Helmet>
        <main>
          <h2>NotFound page</h2>
          <p>luremlurem</p>
        </main>
        <nav>
          <Link to="/">Home</Link>
        </nav>
      </>
    )
}

export default NotFound;