import React from 'react';
import { Outlet } from 'react-router-dom';

function Layout() {
    return (
      <>
        <main>
          <div className='bg-blue-800 h-40 mb-8 relative'>
            <div className='absolute -bottom-4 mx-16'>
              <p className='tracking-[1rem] text-sm text-sky-200 font-bold'>高雄旅遊景點</p>
              <h1 className='text-xl md:text-4xl font-black'>Tourist Attraction</h1>
            </div>
          </div>
            <Outlet />
        </main>
      </>
    )
}

export default Layout;