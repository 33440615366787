import React from 'react';
import {  Outlet} from 'react-router-dom';
import Header from '../components/System/Header';

function Layout() {
    return (
      <div className='bg-gray-900 min-h-screen text-white text-2xl'>
        <Header />
            <main>
                <Outlet />
            </main>
        {/* <Footer /> */}
      </div>
    )
}

export default Layout;