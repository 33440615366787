import './App.css';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Helmet } from "react-helmet";
import NotFound from './components/NotFound';
import Home from './components/Home';
import AttractionsList from './components/AttractionsList';
import Attraction from './components/Attraction';
import DefaultLayout from './layout/default';
import TourLayout from './layout/tour';

function App() {
  return (
    <div className="App">
      <Helmet>
        <html lang="en" />
        <title>六角旅遊網 - 高雄篇</title>
        <link rel="canonical" href="https://hexschool-travel-react.lhwei.me" />
        <meta name="description" content="Hexschool - Exploring in Kaohsiung" />
        <meta name="theme-color" content="#E6E6FA" />
      </Helmet>
      {/* 註冊 router */}
      <Routes>
        <Route path="/" element={<DefaultLayout />}>
          {/* 首頁 */}
          <Route path="/" element={<Home />} />
          {/* 關於 */}
          <Route path="/" element={<Home />} />
          {/* 景點 */}
          <Route path="tour" element={<TourLayout />}>
            {/* 景點一覽 */}
            <Route index element={<AttractionsList />} />
            {/* 個別景點介紹 */}
            <Route path=":Id" element={<Attraction />} />
          </Route>
          {/* 404 */}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
