import React from 'react';
import { Helmet } from "react-helmet";

function Home() {
    return (
      <>
        <Helmet>
            <html lang="en" />
            <title>六角旅遊網</title>
            <link rel="canonical" href="https://hexschool-travel-react.lhwei.me" />
            <meta name="description" content="Hexschool - Exploring in Kaohsiung" />
            <meta name="theme-color" content="#E6E6FA" />
        </Helmet>
        <main className='container mx-auto '>
          <div className='mt-16 grid justify-items-center items-center justify-center'>
            <img src="/logo-web.svg" className='' alt="旅遊" />
            <div className='mt-16'>
              <h1 className='text-2xl font-black'>六角旅遊網－高雄篇</h1>
              <p className='text-xl w-72 mx-auto mt-4'>使用 React 搭建靜態網站，用 fetch API 串接高雄旅遊景點資訊</p>
            </div>
          </div>
          {/* <form method='none' className='my-12 mx-auto'>
            <input placeholder='搜尋...' className='bg-blue-600 text-light-300 rounded-l text-center px-4 py-2'></input>
            <button type='button' className='bg-blue-400 px-4 py-2 rounded-r font-bold'>搜尋 🔍</button>
          </form> */}
        </main>
      </>
    )
}

export default Home;