import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
    return (
      <>
        <nav className='text-xl md:text-3xl flex items-center h-20 justify-end w-1/2 lg:w-1/3 pt-8 text-white border-b-2 pb-2 mb-4'>
          <Link to="/" className='flex'>
            <img className='min-h-12 h-12' src="/logo-web.svg" alt="Home" />
          </Link>
          <span className='px-4'>/</span>
          <Link to="/">
            關於本站
          </Link>
          <span className='px-4'>/</span>
          <Link to="tour">
            高雄景點
          </Link>
        </nav>
      </>
    )
}

export default Header;