import { React, useState, useEffect} from 'react';
import { Link} from 'react-router-dom';
import { Helmet } from "react-helmet";

function AttractionsList() {
    const [data, setData] = useState([]);
    useEffect(()=>{
      fetch("https://api.kcg.gov.tw/api/service/Get/9c8e1450-e833-499c-8320-29b36b7ace5c")
      .then(res => res.json())
      .then(result => {
        // console.table(result.data.XML_Head.Infos.Info);
        const newData = result.data.XML_Head.Infos.Info;
        setData(newData);
      });
    }, []);

    return (
      <>
        <Helmet>
            <html lang="en" />
            <title>六角旅遊網</title>
            <link rel="canonical" href="https://hexschool-travel-react.lhwei.me" />
            <meta name="description" content="Hexschool - Exploring in Kaohsiung" />
            <meta name="theme-color" content="#E6E6FA" />
        </Helmet>
        <main className='grid md:grid-cols-2 justify-items-center justify-center'>
          { data.map((item, index)=>{
            return (
              <div key={index} className="p-12 group ">
                <Link to={`/tour/${item.Id}`}>
                  <img src={ item.Picture1 } className="rounded-2xl pborder-gray-400 border border-b-0" alt={item.Name}/>
                  <div className='-mt-8 ml-8 text-left'>
                    <h2 className='font-bold text-2xl md:text-5xl text-left group-hover:underline group-hover:underline-offset-[-1rem] group-hover:decoration-purple-700 group-hover:decoration-[1.5rem]'>{ item.Name }</h2>
                    <p className='mt-4 text-xl'>{ item.Add }</p>
                    <p className='text-base text-green-300 font-bold'>{ item.Tel }</p>
                  </div>
                </Link>
              </div>
            )
          }) }
        </main>
      </>
    )
}

export default AttractionsList;