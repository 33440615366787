import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";

function Attraction() {
  const { Id } = useParams();
  const [data, setData] = useState({});
  const { Name, Toldescribe, Picture1, Description, Add, Tel, Ticketinfo, Changetime } = data;
  useEffect(()=>{
    fetch("https://api.kcg.gov.tw/api/service/Get/9c8e1450-e833-499c-8320-29b36b7ace5c")
    .then(res => res.json())
    .then(result => {
      const newData = result.data.XML_Head.Infos.Info.filter(item => item.Id === Id);
      setData({...newData[0]});
      console.log(newData);
    });
  }, [Id]);

  return (
    <>
      <Helmet>
          <html lang="en" />
          <title>六角旅遊網</title>
          <link rel="canonical" href="https://hexschool-travel-react.lhwei.me" />
          <meta name="description" content="Hexschool - Exploring in Kaohsiung" />
          <meta name="theme-color" content="#E6E6FA" />
      </Helmet>
      <main className='pb-16'>
        <div className='relative'>
          <div className='absolute -top-36 mx-16 inset-0'>
            <h1 className='text-3xl md:text-6xl font-black text-center'>{ Name }</h1>
          </div>
        </div>
        <div className='container mx-auto flex flex-col gap-y-8 justify-center items-center'>
          <p className='text-xs'>更新時間：{ Changetime }</p>
          <p className='text-base w-3/4 md:w-1/2 underline underline-offset-4 decoration-gray-500'>{ Description }</p>
          <img src={ Picture1 } className='w-3/4 md:w-1/2 rounded-3xl'  alt={Name} />
          <p className='leading-[2rem] text-left text-base w-3/4 md:w-1/2 underline underline-offset-4 decoration-gray-500'>{ Toldescribe }</p>
          <div className='w-3/4 md:w-1/2'>
            <p className='leading-[2rem] text-left text-base'>{ Ticketinfo }</p>
            <p className='leading-[2rem] text-left text-base'>{ Tel }</p>
            <p className='leading-[2rem] text-left text-sm'>{ Add }</p>
          </div>
        </div>
      </main>
    </>
  )
}

export default Attraction;